import React, { ReactElement, useEffect, useState } from "react";

import PiralInstance from "./PiralInstance";

import {config} from "./config";
import Environment,{ENV_VARS} from "./Environment";

const piralBackendGetData = config.getPiralBackendUrl() + "getData";

export default (): (ReactElement | string) => {

  // console.info("piral backend fallback: " + config.PIRAL_BACKEND_FALLBACK);

  const urlParams = new URL(document.location.href).searchParams;
  const mode = urlParams.get("mode");
  let configID = urlParams.get("config");
  Environment.set(ENV_VARS.CONFIG_ID, configID)
  const componentID = urlParams.get("componentID");
  const urlProps = urlParams.get("props");


  if (!configID && !componentID) {
    return "Error: Need configID or componentID - And a new text";
  }

  let subdomain = getTemporarySubdomain();

  console.info("subdomain: " + subdomain);
  console.info("configID: " + configID);

  const [layout, setLayout] = useState(null);
  const [isReady, setReady] = useState(false);
  const [isFetchInProgress, setFetchInProgress] = useState(false);
  const [data, setData] = useState(null);
  
  useEffect(() => {
    if (layout && data) {
      setReady(true);
    }
  }, [isReady, layout]);

  if (layout === null && mode === "preview") {
    setLayout("single");
    return "Loading...";
  }
  if (!data) {
    if (isFetchInProgress === true) {
      return "Loading...";
    }
    // TODO MHA hardcoded layout
    setLayout("single");
    setFetchInProgress(true);
    fetchData(configID, componentID, subdomain).then((data) => {
      setData(data);
      setReady(true);
      setFetchInProgress(false);
    }).catch((error) => {
      console.error(error);
    });
    return "Loading...";
  }

  if (!isReady) {
    return "Loading...";
  }

  // const monitoring = createMonitoring();
  // monitoring.recordPageView(location.pathname);

  return <PiralInstance data={data} subdomain={subdomain}></PiralInstance>
}

async function fetchData(configID?: string, componentID?: string, subdomain?: string) {
  if (subdomain && subdomain.length > 0) {
    subdomain = subdomain + "."
  }

  console.log("piral Backend: ", piralBackendGetData);
  const resp = await fetch(piralBackendGetData, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ config: configID, componentID })
  });
  const json = await resp.json();
  console.warn("Warn to easier see it, received data:", json);
  return json;
}

// TODO extremely temporary until we have a better solution
function getTemporarySubdomain() {
  let hostname = document.location.hostname;
  if (hostname.indexOf("piral.") !== 0) {
    return "";
  }
  hostname = hostname.replace("piral.", "").replace("olive-team.com", "").replace(".", "");
  console.info("subdomain: " + hostname);
  return hostname;
}