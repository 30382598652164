import {isConnectorDefinition,isConnectorInstance,isConnectorTask,isDatapointDefinition,isDatapointInstance,isInlineDatapoint} from "../utils/connectivityUtil";
import {ConnectableElement,ConnectorElement,InlineDatapointImpl} from "@olive/oli-types";
import ConnectivityHolder from "./ConnectivityHolder";

export function getDatapointFromConnectorElement(element: ConnectorElement, connectivityHolder: ConnectivityHolder): ConnectableElement
{
  if(typeof(element) === 'string')
  {
    return connectivityHolder.get(element) as ConnectableElement; 
  }
  if(isConnectorTask(element))
  {
    return getDatapointFromConnectorElement(element.element, connectivityHolder);
  }
  if(isDatapointInstance(element))
  {
    return getDatapointFromConnectorElement(element.datapoint, connectivityHolder);
  }
  if(isInlineDatapoint(element))
  {
    return new InlineDatapointImpl(element) as ConnectableElement;
  }
  if(isDatapointDefinition(element) || isConnectorDefinition(element) || isConnectorInstance(element))
  {
    return element as ConnectableElement;
  }
  return element as ConnectableElement
}